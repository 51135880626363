<template>
  <div
    v-bind:class="{ 'kt-quick-search--has-result': hasResult() }"
    class="kt-quick-search kt-quick-search--dropdown kt-quick-search--result-compact"
    id="kt_quick_search_dropdown"
  >
    <form class="kt-quick-search__form">
      <div
        class="input-group kt-spinner--input kt-spinner--sm kt-spinner--brand kt-spinner--right"
        v-bind:class="{ 'kt-spinner': loading }"
      >
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i class="flaticon2-search-1"></i>
          </span>
        </div>
        <input
          v-on:keyup="onSearch"
          type="text"
          class="form-control kt-quick-search__input"
          placeholder="Search..."
        />
        <div class="input-group-append">
          <span class="input-group-text">
            <i
              class="la la-close kt-quick-search__close"
              v-on:click="reset"
              v-bind:style="{ display: hasResult() ? 'flex' : '' }"
            ></i>
          </span>
        </div>
      </div>
    </form>
    <perfect-scrollbar
      class="kt-quick-search__wrapper"
      style="max-height: 40vh; position: relative;"
    >
      <KTSearchResult v-bind:data="data"></KTSearchResult>
    </perfect-scrollbar>
  </div>
</template>

<script>
import KTSearchResult from "@/views/theme/topbar/SearchResult.vue";

export default {
  name: "KTSearchDefault",
  components: { KTSearchResult },
  data() {
    return {
      data: [],
      searchCriteria: "",
      loading: false,
    }
  },
  methods: {
    onSearch(event) {
      this.loading = true;
      if(event.keyCode == "13") {
        this.loading = false;
        this.$router.push('/work-orders/' + event.target.value);
      }
    },
    /**
     * Check if the data has result
     * @returns {boolean}
     */
    hasResult() {
      return this.data.length || false;
    },
    /**
     * Reset search data
     */
    reset() {
      this.data = [];
    }
  }
};
</script>
